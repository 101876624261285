import { useMatches } from '@remix-run/react';

export const useShouldNotRestore = () => {
  return useMatches().some((match) => {
    if (!match.handle) return false;

    const { handle, data } = match;

    // handle must be an object to continue
    if (typeof handle !== 'object') return false;
    if (handle === null) return false;
    if (Array.isArray(handle)) return false;

    // get notRestore from handle (it may not exist)
    // @ts-ignore
    const notRestore = handle.notRestore as
      | undefined
      | boolean
      | ((data: unknown) => boolean);

    if (!notRestore) return false;

    if (typeof notRestore === 'function') return notRestore(data);
    return notRestore;
  });
};
