import { Links, Meta, Scripts } from '@remix-run/react';
import { useState } from 'react';
import ArrowBackOutlineIcon from '~/assets/icons/arrowBackOutlineIcon';
import EmailOutlineIcon from '~/assets/icons/emailOutlineIcon';
import errorBackground from '~/assets/images/backgrounds/error.png';
import ButtonNew from '~/components/general/ButtonNew';

export function ErrorPage({
  title,
  message,
  requestID,
}: {
  title: string;
  message: string;
  requestID?: string;
}) {
  const [showEmailAddress, setShowEmailAddress] = useState<boolean>(false);

  return (
    <html lang="en" className="dark h-full">
      <head>
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <Meta />
        <Links />
        <title>{title}</title>
      </head>
      <body
        className="flex h-full items-center justify-center"
        style={{
          backgroundImage: `url('${errorBackground}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="w-[630px] text-white">
          <h1 className="text-[80px] font-extrabold leading-normal tracking-normal">
            {title}
          </h1>
          <p className="text-[32px] text-darkMode-gray-light">{message}</p>
          <form method="post" action="mailto:support@getelements.com">
            <div className="flex fill-current pt-6">
              <ButtonNew
                type="button"
                className="mr-2 whitespace-nowrap text-[20px]"
                themeType="shade"
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                <ArrowBackOutlineIcon className="mr-2 w-6 text-darkMode-accent" />
                <span className="pr-4">Back to Work</span>
              </ButtonNew>
              <a className="contents" href="mailto:support@getelements.com">
                <ButtonNew
                  type="button"
                  className="whitespace-nowrap text-[20px]"
                  themeType="shade"
                  onClick={() => {
                    setShowEmailAddress(true);
                  }}
                >
                  <EmailOutlineIcon className="mr-2 w-6 text-darkMode-accent" />
                  Contact Support
                </ButtonNew>
              </a>
            </div>
          </form>
          {showEmailAddress && (
            <>
              <div className="mt-4 text-[16px] text-darkMode-gray-light">
                Email details to{' '}
                <a href="mailto:support@getelements.com">
                  support@getelements.com
                </a>
              </div>
              {requestID && (
                <div className="mt-4 text-[16px] text-darkMode-gray-light">
                  RequestID: {requestID}
                </div>
              )}
            </>
          )}
        </div>
        <Scripts />
      </body>
    </html>
  );
}
