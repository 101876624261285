import {
  ElementsEnvironmentContextProvider,
  ElementsUIContextProvider,
  FeatureFlagsContextProvider,
} from '@elements-financial/elements';
import { Outlet } from '@remix-run/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTypedLoaderData } from 'remix-typedjson';
import { RootDocument } from './components';
import { RootContextProvider } from './context';
import { rootLoader } from './loaders';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { useChangeLanguage } from 'remix-i18next/react';

export const RootPage = () => {
  const { locale, authentication, isDev, featureFlags } = useTypedLoaderData<typeof rootLoader>();

  // https://tanstack.com/query/v5/docs/framework/react/guides/ssr#initial-setup
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: true,
          // Disable cache for now until we fully migrate to react-query
          cacheTime: 0,
        },
      },
    })
  );

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(locale);

  return (
    <RootDocument>
      <ElementsEnvironmentContextProvider
        app="advisor"
        isDev={isDev}
        authentication={{
          accessToken: authentication?.token || '',
          refreshToken: authentication?.refreshToken || '',
        }}
      >
        <FeatureFlagsContextProvider {...featureFlags}>
          <ElementsUIContextProvider>
            <QueryClientProvider client={queryClient}>
              <RootContextProvider isDev={isDev} authentication={authentication}>
                <Outlet />
              </RootContextProvider>
              <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
            </QueryClientProvider>
          </ElementsUIContextProvider>
        </FeatureFlagsContextProvider>
      </ElementsEnvironmentContextProvider>
    </RootDocument>
  );
};
