import { ComponentPropsWithRef } from 'react';

interface EmailOutlineIconProps
  extends Pick<ComponentPropsWithRef<'svg'>, 'className'> {}

export default ({ className }: EmailOutlineIconProps) => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="mail_outline_24px">
      <path
        id="Color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19 18H5C4.45 18 4 17.55 4 17V8L10.94 12.34C11.59 12.75 12.41 12.75 13.06 12.34L20 8V17C20 17.55 19.55 18 19 18ZM12 11L4 6H20L12 11Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
