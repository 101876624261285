import { withSentry } from '@sentry/remix';
import { LinksFunction } from '@remix-run/node';
import { RootPage } from './pages/root';
import { RootDocument, RootErrorBoundary } from './pages/root/components';
import { rootLoader } from './pages/root/loaders';
import { allI18NNamespaces } from './pages/root/util';
import stylesUrl from './styles/app.css';

/**
 *
 * We use the NPM package html-to-image to download PNGs of the full screen scorecard presentation.
 * Because of this issue (https://github.com/bubkoo/html-to-image/issues/362) we need to add crossOrigin="anonymous"
 * to the font files. Comment (https://github.com/bubkoo/html-to-image/issues/49#issuecomment-762222100)
 */
export const links: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: stylesUrl },
  {
    rel: 'stylesheet',
    href: 'https://use.typekit.net/qrq8jit.css',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/icon?family=Material+Icons+Round',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://rsms.me/inter/inter.css',
    crossOrigin: 'anonymous'
  }];

};

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  // i18n: ['common', 'error', 'display'],
  i18n: allI18NNamespaces // TODO: FIXME: this is a hack to load all namespaces for now, need to add the handle to existing routes
};

export const loader = rootLoader;

export const Document = RootDocument;

export const ErrorBoundary = RootErrorBoundary;

export default withSentry(RootPage);