import { useEffect } from 'react';

export const MacOSCustomization = () => {
  useEffect(() => {
    if (!(navigator.userAgent.indexOf('Mac') > 0)) {
      const element = document.getElementsByTagName('body')[0];
      element.classList.add('custom-scroll');
    }
  }, []);
  return null;
};
