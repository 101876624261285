import { Links, Meta, Scripts, ScrollRestoration } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { useTypedLoaderData } from 'remix-typedjson';
import { ClientOnly } from 'remix-utils/client-only';
import { loader } from '~/root';
import { useShouldNotRestore } from '../hooks';
import { MacOSCustomization } from './MacOSCustomization';

export const RootDocument = ({ children }: { children: React.ReactNode }) => {
  const shouldNotRestore = useShouldNotRestore();
  const { i18n } = useTranslation();
  const { locale } = useTypedLoaderData<typeof loader>();

  return (
    <html lang={locale} dir={i18n.dir()} className="dark">
      <head>
        {/* style element from @emotion is being injected here and causing hydration errors */}
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#17181a" />
        <meta name="msapplication-TileColor" content="#17181a" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title></title>
        <Meta />
        <Links />
      </head>
      <body className="dark:bg-background-900 dark:text-darkMode-gray-light">
        {children}
        <div id="portal"></div>
        <div
          id="notification-portal"
          className="pointer-events-none fixed inset-x-0 top-8 z-[50] m-auto flex w-full flex-col items-center gap-4"
        ></div>

        {!shouldNotRestore && <ScrollRestoration />}
        <Scripts />
        {/*<LiveReload />*/}
        <ClientOnly>{() => <MacOSCustomization />}</ClientOnly>
      </body>
    </html>
  );
};
