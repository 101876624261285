import { useLocation } from 'react-use';
import { ErrorPage } from './ErrorPage';
import { FC } from 'react';
import { ErrorResponse } from '@remix-run/node';

export const RootCatchBoundary: FC<{ error: ErrorResponse }> = ({ error }) => {
  const location = useLocation();

  // eslint-disable-next-line no-console
  console.error('Error in CatchBoundary', {
    location: location,
    type: 'CatchBoundary',
    ...error,
  });

  let title = '';
  let message = '';
  if (error.status === 404) {
    // eslint-disable-next-line quotes
    title = "well, that's weird.";
    message =
      'We can\'t find that page. Web nerds refer to this error as a "404."';
  } else {
    title = 'ah, nuts!';
    // eslint-disable-next-line quotes
    message = "It's not you, it's our server. They can be so picky sometimes.";
  }

  return <ErrorPage title={title} message={message} />;
};
