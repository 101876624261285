import { CombinedError } from 'urql';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ErrorPage } from './ErrorPage';
import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { RootCatchBoundary } from '~/pages/root/components/RootCatchBoundary';
import { ErrorResponse } from '@remix-run/node';

export const RootErrorBoundary = () => {
  const routeError = useRouteError();
  captureRemixErrorBoundaryError(routeError);
  if (isRouteErrorResponse(routeError)) {
    // catch boundary no longer exists in remix v2, so this is a nice way to preserve backwards compatibility with our current logic
    return <RootCatchBoundary error={routeError as ErrorResponse} />;
  }

  const nonRouteErrorResponse = routeError as Error | CombinedError;
  let serverRequestID: string | undefined = undefined;
  if ('graphQLErrors' in nonRouteErrorResponse) {
    const mainGraphQLError = nonRouteErrorResponse.graphQLErrors[0];
    serverRequestID = mainGraphQLError.extensions['requestID'] as string;
  }

  // eslint-disable-next-line no-console
  console.error('Error in ErrorBoundary', {
    type: 'ErrorBoundary',
    serverCorrelationID: serverRequestID,
    ...nonRouteErrorResponse,
    message: nonRouteErrorResponse.message,
    name: nonRouteErrorResponse.name,
    stack: nonRouteErrorResponse.stack,
  });

  const title = 'ah, nuts!';
  const message =
    // eslint-disable-next-line quotes
    "It's not you, it's our server. They can be so picky sometimes.";

  return (
    <ErrorPage title={title} message={message} requestID={serverRequestID} />
  );
};
